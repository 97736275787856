<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'movementsequencestep-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'movementsequencestep',
			modelColumnDefs: {}
		};
	},
	computed: {}
};
</script>
